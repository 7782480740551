.circle-label {
  transform: translateY(0.25em);
}

.circle-percentage {
  font-size: 0.6em;
  line-height: 1;
  text-anchor: middle;
  transform: translateY(-0.25em);
}

.circle-text {
  font-size: 0.2em;
  text-transform: uppercase;
  text-anchor: middle;
  transform: translateY(0.7em);
  font-weight: bolder;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #0076c0 !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #00ff00 !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

a {
  text-decoration: none !important;
}

.indicators {
  display: none !important;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  background-color: rgba(31, 149, 228, 0.39) !important;
}

.MuiBox-root {
  padding: .7rem !important;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }
}