@import './variables.scss';

#reset-password-wrapper {
  width: 80%;
  margin-top: 18%;
  min-height: 80vh;
  border-radius: 1rem;
  box-shadow: 10px 10px 20px 11px rgba(0, 0, 0, 0.05);
  background: #fff;

  #first-col {
    margin-top: 0.9rem;
  }

  @include desktop() {
    margin-top: 13vh;
    padding: 5rem;
    height: 75%;
  }

  #reset-head {
    color: addColor(heading-text);
  }
}
