$colors: (
  bg-color: hsl(216, 100%, 91%),
  text-color-one: #111f59,
  text-color-two: #0076c0,
  nav-link: #0076c0cc,
  color-red: red,
  heading-text: #212529,
  bg-color-two: #fff,
);

@function addColor($color-name) {
  @return map-get($map: $colors, $key: $color-name);
}

@mixin landing-page-bg {
  background: url('../assets/images/bg-img.png') no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

$desktop: 1024px;
$ipad: 768px;

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin ipad {
  @media (min-width: #{$ipad}) {
    @content;
  }
}
