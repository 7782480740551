* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
    
  .box {
    background-color: transparent;
    margin: 0 auto;
    border-radius: 3px;
    color: #fff;
    max-width: 450px;
    height: 350px;
    transform-style: preserve-3d;
    perspective: 2000px;
    transition: .4s;
    text-align: center;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0 16px;
      background: transparent;
      border-top: 20px solid #fff;
      border-left: 20px solid #fff;
      box-sizing: border-box;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-bottom: 20px solid #fff;
      border-right: 20px solid #fff;
      box-sizing: border-box;
    }
    .fas {
      font-size: 25px;
      height: 50px;
      width: 50px;
      line-height: 50px !important;
      background-color: #fff;
      color: #2C3A47;
    }
    .fa2 {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
    .text {
      position: absolute;
      top: 30px;
      left: -30px;
      width: calc(100% + 60px);
      height: calc(100% - 60px);
      background-color: #2C3A47;
      border-radius: 3px;
      transition: .4s;
      .fa1 {
        position: absolute;
        top: 0;
        left: 0;
      }
      div {
        text-align: center;
        width: 100%;
        padding: 30px 60px;
        line-height: 1.5;
        box-sizing: border-box;
        h3 {
          font-size: 30px;
          margin-bottom: 5px;
        }
        p {
          font-size: 18px;
        }
      }
    }
    &:hover {
      .text {
        transform: rotateY(20deg) skewY(-3deg)
      }
    }
  }

  @media screen and (max-width: 600px) {
    .box {
      width: 80%;
    }
  }