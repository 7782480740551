@font-face {
  font-family: myFirstFont;
  src: url(./assets/fonts/FontsFree-Net-SFProDisplay-Medium-1.ttf);
}

@font-face {
  font-family: myFirstFontRegular;
  src: url(./assets/FontsFree-Net-SFProDisplay-Regular.ttf);
}

@font-face {
  font-family: myFirstFontLight;
  src: url(./assets/fonts/FontsFree-Net-SFProDisplay-Light.ttf);
}

@font-face {
  font-family: Arno;
  src: url(./assets/fonts/ArnoPro-BoldCaption.otf);
}

#sfPro {
  font-family: myFirstFontRegular !important;
}

#sfProLight {
  font-family: myFirstFontLight !important;
}

html {
  background: linear-gradient(0deg, #F6F8FD, #F6F8FD), #F6F8FD;
  
}
body {
  font-family: myFirstFont, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(0deg, #F6F8FD, #F6F8FD), #F6F8FD;
  overflow-x: hidden;
}
.DateRangePicker_picker {
  position: static !important;
}
a {
  // font-weight: 500 !important;
}

.MuiMenu-paper {
  max-height: 80% !important;
}

.makeStyles-root-480 {
  overflow-x: hidden !important;
}
.link-list {
  &:hover {
    .MuiListItem-button {
      background: #FACF5A;
      // margin: 0 .5rem;
      border-radius: 15px;
      svg, span {
        color: #111
      };
  }
}
}

.activeLink {
  .MuiListItem-button {
    background: #FACF5A;
    // margin: 0 .5rem;
    border-radius: 15px;
    svg, span {
      color: #111
    };

    &:hover {
      background: #FACF5A;
    }
  }
}

.MuiTypography-body1,
.MuiTypography-body2,
.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6,
.MuiTypography-subtitle1,
.MuiTypography-subtitle2 {
  font-family: myFirstFont, sans-serif !important;
}

.MuiTypography-body1 {
  font-size: .95rem !important;
  line-height: 1.53px;
  // font-weight: bold !important;
  // color: #000;
}

.MuiTypography-subtitle1 {
  font-size: 1.085rem !important;
}

.arno {
  font-family: Arno !important;
  font-size: 1.1rem !important;
  letter-spacing: 1.5px !important;
}